// @flow

import React from 'react'

import { useShop } from '../../hooks/useShop'
import { CCRoutes } from '../../utils/routes'

import { CCAlert } from '../UI'

type Props = {
  onClose: () => any
}

export function InvalidFeedLinkAlert ({ onClose: _onClose }: Props) {
  const [shop] = useShop()

  function handleClick (e: any) {
    e.preventDefault()
    window.location.href = CCRoutes.knowledgeBasePath(shop, {
      format: '',
      queryParams: {
        modal: 'qa:link'
      }
    })
  }

  return (
    <CCAlert
      type="error"
      header="Your data source &quot;Feed link&quot; has an issue!"
      content="This may be due to a broken URL, restricted access, data format changes, or network issues."
      buttonActionLabel="Update feed link"
      onActionClick={handleClick}
    />
  )
}
