// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { type KnowledgeBaseFeedLinkSaveSettingsPayload } from '../services'

import { showErrorMessages } from '../components/UI'

import { useShop } from './useShop'

export function useKnowledgeBaseFeedLink () {
  const [shop] = useShop()
  const state = useStoreState(state => state.knowledgeBaseFeedLink)
  const actions = useStoreActions(actions => actions.knowledgeBaseFeedLink)

  async function load (): Promise<void> {
    try {
      await actions.fetch({ shop })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function save (payload: KnowledgeBaseFeedLinkSaveSettingsPayload): Promise<boolean> {
    try {
      const result = await actions.save({ shop, payload })

      return result
    } catch (error) {
      showErrorMessages(error)
    }

    return false
  }

  function resetInvalidData () {
    actions.setIsInvalid(false)
  }

  async function refreshNow (): Promise<boolean> {
    try {
      await actions.refreshNow({ shop })
      return true
    } catch (error) {
      showErrorMessages(error)
    }
    return false
  }

  return { ...state, load, save, resetInvalidData, refreshNow }
}
