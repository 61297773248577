// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'
import { KnowledgeItemsService } from '../services'
import {
  KnowledgeItem,
  Shop,
  type KnowledgeDataSourceType,
  type KnowledgeDataSourceOptionType
} from '../models'

type FetchPayload = {
  shop: Shop,
  params: {
    page: number
  }
}

type ImportFilePayload = {
  shop: Shop,
  file: File
}

type LoadUploadStatusPayload = {
  shop: Shop
}

type SetKnowledgeItemsPayload = {
  shop: Shop
}

type setKnowledgeItemsPayload = {
  shop: Shop,
  id: Number
}

export type KnowledgeItemsModel = {
  knowledgeItems: Array<KnowledgeItem>,
  pages: number,
  page: number,
  count: number,
  search: string | null,
  orderValue: string | null,
  orderDirection: string | null,
  isLoadingList: boolean,
  isUploading: boolean,
  isCreating: boolean,
  isDeleting: boolean,
  dataSourceModalType: KnowledgeDataSourceType | null,
  dataSourceOptionModalType: KnowledgeDataSourceOptionType | null,
  isValidDataSourceModal: boolean,
  setKnowledgeItems: Action<KnowledgeItemsModel, Array<KnowledgeItem>>,
  setIsLoadingList: Action<KnowledgeItemsModel, boolean>,
  setIsUploading: Action<KnowledgeItemsModel, boolean>,
  setIsCreating: Action<KnowledgeItemsModel, boolean>,
  setIsDeleting: Action<KnowledgeItemsModel, boolean>,
  setDataSourceModalType: Action<KnowledgeItemsModel, KnowledgeDataSourceType>,
  setPages: Action<KnowledgeItemsModel, number>,
  setPage: Action<KnowledgeItemsModel, number>,
  setCount: Action<KnowledgeItemsModel, number>,
  setSearch: Action<KnowledgeItemsModel, string>,
  setOrderValue: Action<KnowledgeItemsModel, string>,
  setOrderDirection: Action<KnowledgeItemsModel, string>,
  fetch: Thunk<KnowledgeItemsModel, FetchPayload>,
  importFile: Thunk<KnowledgeItemsModel, ImportFilePayload>,
  loadUploadStatus: Thunk<KnowledgeItemsModel, LoadUploadStatusPayload>,
  editKnowledgeItem: Thunk<KnowledgeItemsModel, setKnowledgeItemsPayload>,
  loadUploadStatus: Thunk<KnowledgeItemsModel, LoadUploadStatusPayload>,
  createManualEntry: Thunk<KnowledgeItemsModel, SetKnowledgeItemsPayload>
}

export const knowledgeItemsModel: KnowledgeItemsModel = {
  knowledgeItems: [],
  pages: 0,
  page: 0,
  count: 0,
  search: null,
  orderValue: null,
  orderDirection: null,
  isLoadingList: true,
  isUploading: false,
  isCreating: false,
  isDeleting: false,
  dataSourceModalType: null,
  dataSourceOptionModalType: null,
  isValidDataSourceModal: false,
  setKnowledgeItems: action((state, payload) => {
    state.knowledgeItems = payload
  }),
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.page = payload
  }),
  setCount: action((state, payload) => {
    state.count = payload
  }),
  setIsUploading: action((state, payload) => {
    state.isUploading = payload
  }),
  setIsCreating: action((state, payload) => {
    state.isCreating = payload
  }),
  setIsDeleting: action((state, payload) => {
    state.isDeleting = payload
  }),
  setDataSourceModalType: action((state, payload) => {
    state.dataSourceModalType = payload
  }),
  setDataSourceOptionModalType: action((state, payload) => {
    state.dataSourceOptionModalType = payload
  }),
  setIsValidDataSourceModal: action((state, payload) => {
    state.isValidDataSourceModal = payload
  }),
  setSearch: action((state, payload) => {
    state.search = payload
  }),
  setOrderValue: action((state, payload) => {
    state.orderValue = payload
  }),
  setOrderDirection: action((state, payload) => {
    state.orderDirection = payload
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { knowledgeItems, pages, page, count } = await KnowledgeItemsService.getList(payload.shop, payload.params)
    actions.setKnowledgeItems(knowledgeItems)
    actions.setPages(pages)
    actions.setPage(page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),
  importFile: thunk(async (actions, payload) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    actions.setIsUploading(true)
    await KnowledgeItemsService.import(payload.shop, formData)
  }),
  loadUploadStatus: thunk(async (actions, payload) => {
    const uploadStatus = await KnowledgeItemsService.loadUploadStatus(payload.shop)
    actions.setIsUploading(uploadStatus === 'in_progress')
  }),
  editKnowledgeItem: thunk(async (actions, payload) => {
    actions.setIsCreating(true)
    const result = await KnowledgeItemsService.editKnowledgeItem(payload.shop, payload.params.id, payload.params)
    actions.setIsCreating(false)
    if (!result) throw new Error('Error while editing Q&A entry, please it try again.')
  }),
  createManualEntry: thunk(async (actions, payload) => {
    actions.setIsCreating(true)
    const result = await KnowledgeItemsService.createManualEntry(payload.shop, payload.params)
    actions.setIsCreating(false)
    if (!result) throw new Error('Q&A entry was not sucesssful, please try it again.')
  }),
  deleteKnowledgeItem: thunk(async (actions, payload) => {
    actions.setIsDeleting(true)
    const result = await KnowledgeItemsService.delete(payload.shop, payload.id)
    actions.setIsDeleting(false)
    if (!result) throw new Error('Q&A entry was not deleted, please it try again.')
  })
}
