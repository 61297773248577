// @flow

export function isDivisible (dividend: number, divisor: number): boolean {
  return (dividend / divisor) % 1 === 0
}

export function formatNumber (value: number, digits: number = 0, locale: string = 'de-DE'): string {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(value)
}

export function formatPercentage (value: number, digits: number = 0, locale: string = 'de-DE'): string {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(value)
}

export function increaseInPercent (previousValue: ?number, currentValue?: number): number {
  if (!previousValue || !currentValue) return 0
  else return Math.round(((currentValue - previousValue) / previousValue) * 100)
}

export function signedNumber (number: number): string {
  return (number < 0 ? '' : '+') + number
}

export function fileSize (size: number): string {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))

  return +((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

export function fileSizeToBytes (size: string): number {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 ** 2,
    GB: 1024 ** 3,
    TB: 1024 ** 4
  }
  const [value, unit] = size.split(' ')

  return parseFloat(value) * units[unit]
}
