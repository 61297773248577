// @flow

import React, { type Node } from 'react'
import classnames from 'classnames'

import './TrboSubSegment.scss'

type Props = {
  children: Node,
  customClasses?: string
}

export function TrboSubSegment ({ children, customClasses }: Props) {
  const classes = classnames('trbo-sub-segment', customClasses)

  return (
    <div className={classes}>
      {children}
    </div>
  )
}
