// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'
import { validateURL } from '../utils/validateURL'

type Webpage = {
  url: string,
  isValid: boolean
}

export type WebpageFormModel = {
  webpages: Array<Webpage>,
  isValidModal: boolean,
  setWebpages: Action<WebpageFormModel, Array<Webpage>>,
  setIsValidModal: Action<WebpageFormModel, boolean>,
  addNewWebpage: Action<WebpageFormModel, void>,
  deleteWebpage: Action<WebpageFormModel, number>,
  updateWebpage: Action<WebpageFormModel, { index: number, url: string }>,

  validateAndUpdateWebpage: Thunk<WebpageFormModel, { index: number, url: string }>
}

export const webpageFormModel: WebpageFormModel = {
  webpages: [{ url: '', isValid: false }],
  isValidModal: false,

  setWebpages: action((state, updatedWebpages) => {
    state.webpages = updatedWebpages
  }),

  setIsValidModal: action((state, isValid) => {
    state.isValidModal = isValid
  }),

  addNewWebpage: action((state) => {
    state.webpages.push({ url: '', isValid: false })
  }),

  pushWebpage: action((state, { url, isValid }) => {
    state.webpages.push({ url, isValid })
    state.isValidModal = state.webpages.every(item => item.isValid)
  }),

  deleteWebpage: action((state, index) => {
    state.webpages = state.webpages.filter((_, i) => i !== index)
    state.isValidModal = state.webpages.length > 0 && state.webpages.every(item => item.isValid)
  }),

  updateWebpage: action((state, { index, url }) => {
    state.webpages[index].url = url
    state.webpages[index].isValid = validateURL(url)
    state.isValidModal = state.webpages.every(item => item.isValid)
  }),

  validateAndUpdateWebpage: thunk((actions, { index, url }, { getState }) => {
    const { webpages } = getState()
    const updatedWebpages = [...webpages]
    updatedWebpages[index].url = url
    updatedWebpages[index].isValid = validateURL(url)

    actions.setWebpages(updatedWebpages)

    const allValid = updatedWebpages.every(item => item.isValid)
    actions.setIsValidModal(allValid)
  }),

  handleSave: thunk(async (actions, _, { getState }) => {
    const { webpages } = getState()

    // todo: replace this with API call in backend ticket
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      // eslint-disable-next-line no-console
      console.log('Saving webpages:', webpages)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving webpages:', error)
    }
  })
}
