// @flow

export class FeatureFlagService {
  static shouldShowOnStaging (): boolean {
    return FeatureFlagService.isOnStaging() || FeatureFlagService.isOnLocal()
  }

  static shouldShowOnProduction (): boolean {
    return FeatureFlagService.isOnProduction()
  }

  static isOnProduction (): boolean {
    return window.location.hostname === 'app.chatchamp.com'
  }

  static isOnStaging (): boolean {
    return window.location.hostname === 'app-staging.chatchamp.com'
  }

  static isOnLocal (): boolean {
    return window.location.hostname === 'app.chatchamp.local'
  }
}
