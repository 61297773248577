// @flow

export type KnowledgeItemStatus = 'training' | 'trained' | 'error'
export type KnowledgeItemInputSource = 'csv' | 'manual'

export class KnowledgeItem {
  id: number
  question: string
  answer: string
  input_source: KnowledgeItemInputSource
  status: KnowledgeItemStatus
  link: string

  constructor (knowledgeItem: Object) {
    knowledgeItem && Object.assign(this, knowledgeItem)
  }
}
