// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { Alert } from '../models'

import { useShop } from './useShop'

export function useAlerts () {
  const [shop] = useShop()
  const state = useStoreState((store) => store.alerts)
  const actions = useStoreActions((store) => store.alerts)

  async function loadAlerts (): Promise<void> {
    await actions.fetch({ shop })
  }

  function hideAlert (alertToHide: Alert) {
    actions.hideAlert(alertToHide)
  }

  return { ...state, loadAlerts, hideAlert }
}
