// @flow

import React, { useState, useEffect } from 'react'

import { KnowledgeItem } from '../../../../../../models'
import { useKnowledgeItems, useShop } from '../../../../../../hooks'

import { CCAlert, CCButton, CCModal, CCTextInput, showSuccessMessage, showErrorMessage, TrboSubSegment } from '../../../../../UI'

import '../ActionsCell.scss'
import { CCTextArea } from '../../../../../UI/CCTextArea/CCTextArea'

type Props = {
  row: {
    original: KnowledgeItem
  },
  onEditRequestClose: Function
}

export function EditModal ({ row, onEditRequestClose }: Props) {
  const [shop] = useShop()
  const [question, setQuestion] = useState(row.original.question)
  const [answer, setAnswer] = useState(row.original.answer)
  const [knowledgeItem, setKnowledgeItem] = useState(row.original)
  const {
    isCreating,
    editKnowledgeItem,
    loadKnowledgeItems
  } = useKnowledgeItems()

  function handleQuestionChange (value) {
    setQuestion(value)
  }

  function handleAnswerChange (value) {
    setAnswer(value)
  }

  useEffect(() => {
    setKnowledgeItem({ id: row.original.id, question: question, answer: answer })
  }, [question, answer])

  async function onSaveClick () {
    if (knowledgeItem.question !== undefined && knowledgeItem.answer !== undefined) {
      if (!shop.secure_id) return

      if (await editKnowledgeItem(knowledgeItem)) {
        showSuccessMessage('Changes will be reflected in chatbot soon', 'Data entry edited!')
        loadKnowledgeItems()
      } else {
        showErrorMessage('Error while updating Q&A entry')
      }
    }
    onEditRequestClose()
  }

  function closeEditModal () {
    if (!isSaveButtonDisabled) {
      const isConfirmed = window.confirm('You have unsaved changes, are you sure you want to continue?')
      if (isConfirmed) {
        setQuestion(row.original.question)
        setAnswer(row.original.answer)
        onEditRequestClose()
      } else {
        return null
      }
    }
    setQuestion(row.original.question)
    setAnswer(row.original.answer)
    onEditRequestClose()
  }

  const isSaveButtonDisabled = (question === row.original.question && answer === row.original.answer) || (question === '') || (answer === '')

  return (
    <CCModal
      isOpen
      width={900}
      title="Edit Q&A"
      confirmLabel="Save"
      onRequestClose={closeEditModal}
      customClasses="k-base__edit-manual__modal"
      hasNewStyle
    >
      <CCAlert type="warning" header="Important!" content="Editing data manually will update chatbot responses, and changes cannot be undone." inline />
      <TrboSubSegment>
        <div className="k-base__edit-manual__content">
          <div className="k-base__edit-manual__heading">Question</div>
          <CCTextInput
            customClasses="question"
            placeholder="Enter your question"
            value={question}
            onChange={handleQuestionChange}
          />
        </div>
        <div className="k-base__edit-manual__content">
          <div className="k-base__edit-manual__heading">Answer</div>
          <CCTextArea
            customClasses="answer"
            placeholder="Enter your answer"
            value={answer}
            onChange={handleAnswerChange}
          />
        </div>
      </TrboSubSegment>
      <div className="kb-data-source-settings-template__actions">
        <CCButton
          onClick={closeEditModal}
        >
          Cancel
        </CCButton>
        <CCButton
          color="primary"
          disabled={isSaveButtonDisabled || isCreating}
          onClick={onSaveClick}
        >
          Save
        </CCButton>
      </div>
    </CCModal>
  )
}
