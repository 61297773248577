// @flow

import React from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'

import { CCButton } from '../CCButton/CCButton'

import './CCModal.scss'

type Props = {
  children: any,
  title?: any,
  description?: any,
  width?: number,
  customClasses?: string,
  headerButton?: any,
  headerIcon?: string,
  className?: string,
  isOpen: boolean,
  actions?: boolean,
  cancelButtonText?: string,
  saveButtonText?: string,
  saveButtonDisabled?: boolean,
  saveButtonColor?: string,
  onSave?: Function,
  hasNewStyle?: boolean,
  onRequestClose?: Function,
  smallBackButton?: boolean,
  onBackClick?: Function
}

export function CCModal ({
  children,
  title,
  description,
  width = 450,
  customClasses,
  headerButton,
  headerIcon,
  className,
  isOpen,
  actions,
  cancelButtonText,
  saveButtonText,
  saveButtonDisabled,
  saveButtonColor,
  onSave,
  hasNewStyle,
  onRequestClose,
  smallBackButton = false,
  onBackClick
}: Props) {
  const modalClasses = classNames('cc-modal', `cc-modal-${width}`, className, {
    'cc-modal--has-new-style': hasNewStyle
  })
  const classes = classNames('cc-modal__body', customClasses)

  return (<ReactModal
    overlayClassName="cc-modal-overlay"
    contentLabel={title}
    isOpen={isOpen}
    className={modalClasses}
    shouldCloseOnOverlayClick={false}
    appElement={document.querySelector('.content-wrapper')}
    onRequestClose={onRequestClose}
  >
    {onRequestClose && (
      <div className="cc-modal__close" onClick={onRequestClose}>
        <i className="fa6 fa6-regular fa6-close"/>
      </div>
    )}
    {title && <div className="cc-modal__header">
      {headerIcon && (
        <div className="cc-modal__header__icon">
          <i className={`fa6 fa6-regular fa6-${headerIcon}`} />
        </div>
      )}
      {smallBackButton && (
        <div className="cc-modal__small-back-button">
          <CCButton icon="arrow-left" iconVersion="6" iconWeight="regular" onClick={onBackClick} />
        </div>
      )}
      <div className="cc-modal__header__title-and-description">
        <div className="cc-modal__header__title">{title}</div>
        {description && (
          <div className="cc-modal__header__description">{description}</div>
        )}
      </div>
      {headerButton}
    </div>}
    <div className={classes}>
      {children}
    </div>
    {actions &&
        <>
          <div className="cc-modal__spacer"></div>
          <div className="cc-modal__actions">
            <div>
              <CCButton
                onClick={onRequestClose}
              >
                {cancelButtonText}
              </CCButton>
              <CCButton
                disabled={saveButtonDisabled}
                color={saveButtonColor}
                onClick={onSave}
              >
                {saveButtonText}
              </CCButton>
            </div>
          </div>
        </>
      }
  </ReactModal>)
}
