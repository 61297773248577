// @flow

import React from 'react'

import './CCCheckbox.scss'

type Props = {
  label?: string,
  [key: string]: any,
  counterLabel?: number
}

export function CCCheckbox ({ counterLabel, label, ...props }: Props) {
  return (
    <div className="cc-checkbox">
      <label className="cc-checkbox__label">
        <input {...props} />
        <span className="cc-checkbox__container">
          <svg className="cc-checkbox__container__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.24636 4.48914L5.20107 8.53443L3.3623 6.69566" stroke="white" strokeWidth="1.26087" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
        {label && (
          <span className="cc-checkbox__label__text">{label}</span>
        )}
        {counterLabel && (
          <span className="cc-checkbox__counter-label">{counterLabel} entries</span>
        )}
      </label>
    </div>
  )
}
