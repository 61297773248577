// @flow

export class Alert {
  name: string
  isVisible: boolean

  constructor (alert: Object) {
    alert && Object.assign(this, alert)
    this.isVisible = alert.isVisible ?? true
  }
}
