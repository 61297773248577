// @flow

import React from 'react'

import './CCSpinnerSmall.scss'

export function CCSpinnerSmall () {
  return (
    <div className="cc-spinner-small" role="spinner-small">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" />
        <path d="M21.1189 12.0009C21.1189 14.3946 20.1778 16.6924 18.4986 18.3984C16.8194 20.1044 14.5367 21.0818 12.1433 21.1197C9.74981 21.1576 7.4374 20.253 5.70503 18.601C3.97267 16.949 2.95926 14.6821 2.88348 12.2896C2.8077 9.897 3.67563 7.57056 5.29998 5.81226C6.92433 4.05396 9.17486 3.00479 11.5659 2.89114C13.957 2.7775 16.2969 3.60849 18.0807 5.2048C19.8645 6.80112 20.9491 9.03476 21.1006 11.4237L18.3701 11.5969C18.2641 9.92459 17.5048 8.36104 16.2562 7.24362C15.0075 6.1262 13.3696 5.54451 11.6958 5.62406C10.0221 5.70361 8.4467 6.43803 7.30966 7.66884C6.17261 8.89965 5.56506 10.5282 5.61811 12.203C5.67115 13.8778 6.38054 15.4646 7.5932 16.6209C8.80585 17.7773 10.4245 18.4106 12.1 18.3841C13.7754 18.3575 15.3732 17.6734 16.5487 16.4792C17.7241 15.285 18.3829 13.6765 18.3829 12.0009H21.1189Z" fill="#D9D9D9" />
        <path d="M21.1189 12.0009C21.1189 10.8032 20.883 9.61728 20.4247 8.51079C19.9664 7.4043 19.2946 6.39892 18.4477 5.55205C17.6008 4.70518 16.5955 4.0334 15.489 3.57508C14.3825 3.11676 13.1966 2.88086 11.9989 2.88086V5.61686C12.8373 5.61686 13.6674 5.78199 14.442 6.10281C15.2165 6.42364 15.9203 6.89388 16.5131 7.48669C17.1059 8.0795 17.5761 8.78327 17.897 9.55781C18.2178 10.3324 18.3829 11.1625 18.3829 12.0009H21.1189Z" fill="#10B981" />
      </svg>
    </div>
  )
}
