// @flow

export type KnowledgeBaseFeedLinkFrequency = 'daily' | 'weekly' | 'monthly'

export type KnowledgeBaseFeedLinkStatus = 'valid' | 'invalid'

export class KnowledgeBaseFeedLink {
  id: number
  feed_link: string
  frequency: KnowledgeBaseFeedLinkFrequency
  last_import: ?string
  next_import: ?string
  status: ?KnowledgeBaseFeedLinkStatus

  constructor (knowledgeBaseFeedLink: Object) {
    knowledgeBaseFeedLink && Object.assign(this, knowledgeBaseFeedLink)
  }
}
