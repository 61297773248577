// @flow

import React from 'react'

import { useKnowledgeItems } from '../../../../../../../hooks'

import '../qa/QAOptions.scss'
import { WebsiteOptions } from './WebsiteOptions'
import { WebpageForm } from './webpage/WebpageForm'

export function WebsiteDataSource () {
  const { dataSourceOptionModalType } = useKnowledgeItems()

  return (
    <div className="kb-qa-data-source">
      {!dataSourceOptionModalType && <WebsiteOptions />}
      {dataSourceOptionModalType === 'webpages' && <WebpageForm />}
    </div>
  )
}
