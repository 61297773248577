import React from 'react'

import classNames from 'classnames'

import './CCAlert.scss'

export type AlertType =
  | 'info'
  | 'warning'
  | 'error'

export type Props = {
  type: AlertType,
  header?: React.ReactNode,
  centered?: boolean,
  content: React.ReactNode,
  inline?: boolean,
  buttonActionLabel?: string,
  onActionClick?: () => any
}

const TYPE_ICONS = {
  info: { name: 'circle-info', weight: 'bold' },
  warning: { name: 'warning', weight: 'bold' },
  error: { name: 'warning', weight: 'bold' }
}

export function CCAlert ({
  type,
  header,
  centered,
  content,
  inline,
  buttonActionLabel,
  onActionClick
}: Props) {
  const classes = classNames('cc-alert', {
    [`cc-alert--${type}`]: !!type,
    'cc-alert--centered': centered
  })
  const contentClasses = classNames('cc-alert__content', {
    inline: inline
  })

  return (
    <div role="alert" className={classes}>
      <i className={`fa fa-${TYPE_ICONS[type].name}`} />
      <div className={contentClasses}>
        <div className="cc-alert__header">{header}</div>
        <div className="cc-alert__description">{content}</div>
      </div>
      {buttonActionLabel && (
        <div className="cc-alert__action" onClick={onActionClick}>
          {buttonActionLabel}
        </div>
      )}
    </div>
  )
}
