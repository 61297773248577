// @flow

import { alertsModel, type AlertsModel } from './alertsModel'
import { accountUsersModel, type AccountUsersModel } from './accountUsersModel'
import { attributesModel, type AttributesModel } from './attributesModel'
import { attributeValuesModel, type AttributeValuesModel } from './attributeValuesModel'
import { blocksModel } from './blocksModel'
import { categoriesModel } from './categoriesModel'
import { conversationsModel, type ConversationsModel } from './conversationModel'
import { issuesModel } from './issuesModel'
import { knowledgeItemsModel, type KnowledgeItemsModel } from './knowledgeItemsModel'
import { knowledgeBaseFeedLinkModel, type KnowledgeBaseFeedLinkModel } from './knowledgeBaseFeedLinkModel'
import { shopModel } from './shopModel'
import { productsModel } from './productsModel'
import { userChatsModel, type UserChatsModel } from './userChatsModel'
import { webpageFormModel, type WebpageFormModel } from './webpageFormModel'
import { wizardModel } from './wizardModel'

export type StoreModel = {
  alerts: AlertsModel,
  attributes: AttributesModel,
  accountUsers: AccountUsersModel,
  attributeValues: AttributeValuesModel,
  blocks: any,
  categories: any,
  conversations: ConversationsModel,
  issues: any,
  knowledgeItems: KnowledgeItemsModel,
  knowledgeBaseFeedLink: KnowledgeBaseFeedLinkModel,
  shop: any,
  products: any,
  userChats: UserChatsModel,
  webpageForm: WebpageFormModel,
  wizard: any
}

export const model: StoreModel = {
  alerts: alertsModel,
  accountUsers: accountUsersModel,
  attributes: attributesModel,
  attributeValues: attributeValuesModel,
  blocks: blocksModel,
  categories: categoriesModel,
  conversations: conversationsModel,
  issues: issuesModel,
  knowledgeItems: knowledgeItemsModel,
  knowledgeBaseFeedLink: knowledgeBaseFeedLinkModel,
  shop: shopModel,
  products: productsModel,
  userChats: userChatsModel,
  webpageForm: webpageFormModel,
  wizard: wizardModel
}
