// @flow

import { CCRoutes } from '../utils/routes'
import { KnowledgeBaseFeedLink, Shop } from '../models'

import { axiosJson } from './utils'

export type KnowledgeBaseFeedLinkSaveSettingsPayload = {
  feed_link: string,
  frequency: string
}

export class KnowledgeBaseFeedLinksService {
  static getSettings (shop: Shop, params: Object = {}): Promise<?KnowledgeBaseFeedLink> {
    return axiosJson.get(CCRoutes.knowledgeBaseFeedLinksPath(shop, params))
      .then(({ data }) => data?.knowledge_base_feed_link ? new KnowledgeBaseFeedLink(data.knowledge_base_feed_link) : null)
  }

  static saveSettings (shop: Shop, payload: KnowledgeBaseFeedLinkSaveSettingsPayload): Promise<boolean> {
    return axiosJson.post(CCRoutes.knowledgeBaseFeedLinksPath(shop), { knowledge_base_feed_link: payload })
      .then(() => true)
      .catch(() => false)
  }

  static refreshNow (shop: Shop): Promise<boolean> {
    return axiosJson.post(CCRoutes.knowledgeBaseFeedLinksRefreshNowPath(shop), {})
      .then(({ data }) => true)
      .catch(() => false)
  }
}
