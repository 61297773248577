// @flow

import React, { useEffect, useState } from 'react'

import {
  useAlerts,
  useKnowledgeBaseFeedLink,
  useKnowledgeItems
} from '../../../../../../../../hooks'
import { validateURL } from '../../../../../../../../utils/validateURL'

import {
  CCAlert,
  CCDropdown,
  CCSpinnerSmall,
  CCTextInput,
  showSpinnerMessage,
  TrboIcon,
  TrboModalControl,
  TrboSubSegment
} from '../../../../../../../UI'

import { DataSourceSettingsTemplate } from '../../../DataSourceSettingsTemplate'

import './QAFeedLinkForm.scss'

export function QAFeedLinkForm () {
  const { alerts, hideAlert } = useAlerts()
  const {
    isValidDataSourceModal,
    setIsValidDataSourceModal,
    setDataSourceOptionModalType,
    closeModal
  } = useKnowledgeItems()
  const {
    settings: knowledgeBaseFeedLinkSettings,
    save: saveKnowledgeBaseFeedLink,
    isLoading: isLoadingKnowledgeBaseFeedLink,
    isSaving: isSavingKnowledgeBaseFeedLink,
    isInvalid: isInvalidKnowledgeBaseFeedLink,
    resetInvalidData
  } = useKnowledgeBaseFeedLink()
  const [feedLink, setFeedLink] = useState('')
  const [frequency, setFrequency] = useState('weekly')
  const isValidURL = feedLink ? validateURL(feedLink) : true
  const hasChanged = (
    knowledgeBaseFeedLinkSettings?.feed_link !== feedLink ||
    knowledgeBaseFeedLinkSettings?.frequency !== frequency
  )
  const isSaveButtonDisabled = !isValidDataSourceModal || isSavingKnowledgeBaseFeedLink || !hasChanged

  function handleChangeURL (text) {
    setFeedLink(text)
    setIsValidDataSourceModal(text && validateURL(text))
    resetInvalidData()
  }

  function handleChangeFrequency (item) {
    setFrequency(item.value)
  }

  function onBackClick () {
    setIsValidDataSourceModal(false)
    setDataSourceOptionModalType(null)
    resetInvalidData()
  }

  async function onSaveClick () {
    const result = await saveKnowledgeBaseFeedLink({
      feed_link: feedLink,
      frequency
    })

    if (result) {
      const alert = alerts.find((alert) => alert.name === 'invalidFeedLinkAlert')
      if (alert) hideAlert(alert)
      showSpinnerMessage('Data added and training is in a process. You will receive an email with confirmation message', 'Training in progress')
      closeModal()
    }
  }

  const dropdownOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' }
  ]

  useEffect(() => {
    if (feedLink) setIsValidDataSourceModal(isValidURL)
  }, [feedLink, isValidURL])

  useEffect(() => {
    if (knowledgeBaseFeedLinkSettings) {
      setFeedLink(knowledgeBaseFeedLinkSettings?.feed_link || '')
      setFrequency(knowledgeBaseFeedLinkSettings?.frequency || 'weekly')
    }
  }, [knowledgeBaseFeedLinkSettings])

  return (
    <DataSourceSettingsTemplate
      isSaving={isSavingKnowledgeBaseFeedLink}
      buttonSaveLabel="Import data"
      savingLabel="Validating link"
      isSaveButtonDisabled={isSaveButtonDisabled}
      onBackClick={onBackClick}
      onSaveClick={onSaveClick}
    >
      {isLoadingKnowledgeBaseFeedLink && (
        <div className="kb-qa-feedlink-form">
          <div className="kb-qa-feedlink-spinner">
            {/* Thiago; 03.10.2024; Replace spinner by placeholder when it is available in the main branch. */}
            <CCSpinnerSmall />
          </div>
        </div>
      )}
      {!isLoadingKnowledgeBaseFeedLink && (
        <div className="kb-qa-feedlink-form">
          {knowledgeBaseFeedLinkSettings &&
            <CCAlert
              type="warning"
              content={<div className="kb-qa-feedlink-form__alert"><strong>Important!</strong> New/updated feed link will automatically update matching info without losing existing data. <br /> The system will either update similar entries or add new ones if they weren’t included before.</div>}
              inline
            />
          }
          <TrboSubSegment>
            <div className="kb-qa-feedlink__groups">
              <div className="kb-qa-feedlink__groups__controls">
                <TrboModalControl label="Feed link">
                  <div className="kb-qa-feedlink__input-container">
                    <CCTextInput
                      customClasses={!isValidURL ? 'cc-text-input--error' : ''}
                      type="text"
                      placeholder="https://www.example.com"
                      value={feedLink}
                      error={isInvalidKnowledgeBaseFeedLink}
                      onChange={handleChangeURL}
                    />
                    <TrboIcon name="link-simple" />
                    {(!isValidURL || isInvalidKnowledgeBaseFeedLink) &&
                      <TrboIcon name="exclamation-triangle" />
                    }
                  </div>
                </TrboModalControl>
                <TrboModalControl label="Update frequency">
                  <div className="kb-qa-feedlink__input-container">
                    <CCDropdown
                      options={dropdownOptions}
                      value={frequency}
                      onChange={handleChangeFrequency}
                    />
                    <TrboIcon name="calendar" />
                  </div>
                </TrboModalControl>
              </div>
              {!isValidURL &&
                <div className="kb-qa-feedlink-form__invalid">Invalid URL</div>
              }
              {isInvalidKnowledgeBaseFeedLink && (
                <div className="kb-qa-feedlink-form__invalid">We had trouble accessing your data source, possibly due to a broken URL, restricted link, data format change, or network issues.</div>
              )}
            </div>
          </TrboSubSegment>
        </div>
      )}
    </DataSourceSettingsTemplate>
  )
}
