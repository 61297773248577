// @flow

import React from 'react'
import classnames from 'classnames'

import './CCTextArea.scss'

type Props = {
  customClasses?: string,
  disabled?: boolean,
  onChange: Function,
  placeholder?: string,
  value: string,
}

export function CCTextArea ({ customClasses, disabled, onChange, placeholder, value }: Props) {
  const classes = classnames('cc-text-area', customClasses)

  function handleChange (e) {
    onChange(e.target.value)
  }

  return (
    <div className={classes}>
      <textarea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}
