// @flow

import React, { useState } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { FileRejection, useDropzone } from 'react-dropzone'

import { fileSize } from '../../../utils/number'

import { IconExtension } from './IconExtension'

import './TrboUploader.scss'

type Props = {
  acceptExtensions?: Array<string>,
  maxSize?: number,
  maxFiles?: number,
  onSelect: (files: Array<File>) => void,
  onError: (errors: Array<string>) => void
}

function extensionsToMimeType (extensions: Array<string>): Array<string> {
  const mimeTypes = {
    csv: 'text/csv'
  }

  return extensions.map((extension) => {
    return mimeTypes[extension]
  }).filter(Boolean)
}

export function TrboUploader ({
  acceptExtensions,
  maxSize,
  maxFiles = 1,
  onSelect,
  onError
}: Props) {
  const [hasError, setHasError] = useState(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles,
    maxSize,
    multiple: maxFiles > 1,
    accept: acceptExtensions ? extensionsToMimeType(acceptExtensions) : '',
    onDrop: handleDrop
  })
  const classes = classNames('trbo-uploader', {
    'trbo-uploader--is-dragging': isDragActive,
    'trbo-uploader--has-error': hasError
  })

  function handleDrop (files: Array<File>, fileRejection: Array<FileRejection> = []) {
    if (files.length > 0) {
      setHasError(false)
      onSelect(files)
    } else {
      setHasError(true)
      onError(
        _.uniq(fileRejection.map((item) => item.errors).flat().map(item => item.message))
      )
    }
  }

  return (
    <div className={classes} {...getRootProps()}>
      <input {...getInputProps()} role="input-file"/>
      <div className="trbo-uploader__extensions">
        <IconExtension extensions={acceptExtensions} />
      </div>
      <div className="trbo-uploader__title">
        <strong>Click to upload</strong>
        <span> or drag and drop</span>
      </div>
      <div className="trbo-uploader__description">
        {acceptExtensions && (
          <span>{acceptExtensions?.map(e => `.${e}`).join(' ').toUpperCase()} file</span>
        )}
        {!acceptExtensions && (
          <span>All files are accepted</span>
        )}
        {maxSize && (
          <span> (max. <strong>{fileSize(maxSize)}</strong> allowed value here)</span>
        )}
      </div>
    </div>
  )
}
