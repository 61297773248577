// @flow

import React, { type Node } from 'react'

import { TrboIcon } from '../TrboIcon/TrboIcon'

import './TrboModalControl.scss'

type Props = {
  label: string,
  children: Node,
  error?: string
}

export function TrboModalControl ({ label, children, error }: Props) {
  return (
    <div className="trbo-modal-control__item">
      <div className="trbo-modal-control__item__header">
        {label}
      </div>
      <div className="trbo-modal-control__item__content">
        {children}
      </div>
      {error && (
        <div className="trbo-modal-control__item__error">
          <TrboIcon name="warning" />
          <span>{error}</span>
        </div>
      )}
    </div>
  )
}
