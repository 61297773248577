// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'

import {
  KnowledgeBaseFeedLinksService,
  type KnowledgeBaseFeedLinkSaveSettingsPayload
} from '../services'
import { Shop, KnowledgeBaseFeedLink } from '../models'

type FetchPayload = {
  shop: Shop
}

type SavePayload = {
  shop: Shop,
  payload: KnowledgeBaseFeedLinkSaveSettingsPayload
}

export type KnowledgeBaseFeedLinkModel = {
  settings: ?KnowledgeBaseFeedLink,
  isLoading: boolean,
  isSaving: boolean,
  isInvalid: boolean,
  isRefreshing: boolean,
  setSettings: Action<KnowledgeBaseFeedLinkModel, KnowledgeBaseFeedLink>,
  setIsLoading: Action<KnowledgeBaseFeedLinkModel, boolean>,
  setIsSaving: Action<KnowledgeBaseFeedLinkModel, boolean>,
  setIsInvalid: Action<KnowledgeBaseFeedLinkModel, boolean>,
  setIsRefreshing: Action<KnowledgeBaseFeedLinkModel, boolean>,
  fetch: Thunk<KnowledgeBaseFeedLinkModel, FetchPayload>,
  save: Thunk<KnowledgeBaseFeedLinkModel, SavePayload>
}

export const knowledgeBaseFeedLinkModel: KnowledgeBaseFeedLinkModel = {
  settings: null,
  isLoading: true,
  isSaving: false,
  isInvalid: false,
  isRefreshing: false,
  setSettings: action((state, payload) => {
    state.settings = payload
  }),
  updateSettings: action((state, payload) => {
    state.settings = new KnowledgeBaseFeedLink({
      ...state.settings,
      ...payload
    })
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsSaving: action((state, payload) => {
    state.isSaving = payload
  }),
  setIsInvalid: action((state, payload) => {
    state.isInvalid = payload
  }),
  setIsRefreshing: action((state, payload) => {
    state.isRefreshing = payload
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoading(true)
    const settings = await KnowledgeBaseFeedLinksService.getSettings(payload.shop)
    actions.setSettings(settings)
    actions.setIsLoading(false)
    if (settings?.status === 'invalid') actions.setIsInvalid(true)
  }),
  save: thunk(async (actions, payload) => {
    actions.setIsInvalid(false)
    actions.setIsSaving(true)
    const result = await KnowledgeBaseFeedLinksService.saveSettings(payload.shop, payload.payload)
    actions.setIsSaving(false)
    if (result) actions.updateSettings(payload.payload)
    else actions.setIsInvalid(true)

    return result
  }),
  refreshNow: thunk(async (actions, payload) => {
    actions.setIsRefreshing(true)
    const result = await KnowledgeBaseFeedLinksService.refreshNow(payload.shop)
    actions.setIsRefreshing(false)

    return result
  })
}
