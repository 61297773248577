// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'
import { validateURL } from '../utils/validateURL'

export function useWebpageForm () {
  const state = useStoreState((state) => state.webpageForm)
  const actions = useStoreActions((actions) => actions.webpageForm)

  function handleChangeURL (index, url) {
    actions.validateAndUpdateWebpage({ index, url })
  }

  function addNewWebpage () {
    actions.addNewWebpage()
  }

  function clearWebpages () {
    actions.setWebpages([{ url: '', isValid: false }])
  }

  function handleDelete (index) {
    actions.deleteWebpage(index)
  }

  function handlePaste (e, index) {
    e.preventDefault()
    const pasteText = e.clipboardData.getData('text')
    const pastedURLs = pasteText.split(/\s+/).filter((url) => validateURL(url))

    if (pastedURLs.length > 0) {
      actions.updateWebpage({ index, url: pastedURLs[0] })

      for (let i = 1; i < pastedURLs.length; i++) {
        actions.pushWebpage({ url: pastedURLs[i], isValid: validateURL(pastedURLs[i]) })
      }
    }
  }

  async function handleSave () {
    await actions.handleSave()
    clearWebpages()
  }

  return {
    ...state,
    handleChangeURL,
    addNewWebpage,
    clearWebpages,
    handleDelete,
    handlePaste,
    handleSave
  }
}
