// @flow

import { type Node } from 'react'

import { FeatureFlagService } from '../../services'

type Props = {
  showOnStaging?: boolean,
  showOnProduction?: boolean,
  children: Node
}

export function FeatureFlag ({ showOnStaging, showOnProduction, children }: Props) {
  if (showOnStaging) {
    if (!FeatureFlagService.shouldShowOnStaging()) return null
  }

  if (showOnProduction) {
    if (!FeatureFlagService.shouldShowOnProduction()) return null
  }

  return children
}
