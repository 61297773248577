// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'

import { Shop, Alert } from '../models'
import { AlertsService } from '../services'

type FetchPayload = {
  shop: Shop
}

type SetVisibilityPayload = {
  name: string
}

export type AlertsModel = {
  alerts: Array<Alert>,
  setAlerts: Action<AlertsModel, Array<Alert>>,
  hideAlert: Action<AlertsModel, SetVisibilityPayload>,
  fetch: Thunk<AlertsModel, FetchPayload>
}

export const alertsModel: AlertsModel = {
  alerts: [],
  setAlerts: action((state, payload) => {
    state.alerts = payload
  }),
  hideAlert: action((state, payload) => {
    state.alerts = state.alerts.map((alert) => {
      if (payload.name === alert.name) {
        return new Alert({ ...alert, isVisible: false })
      }
      return alert
    })
  }),
  fetch: thunk(async (actions, payload) => {
    const alerts = await AlertsService.loadAlerts(payload.shop)
    actions.setAlerts(alerts)
  })
}
