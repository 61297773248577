// @flow

import React from 'react'

import { TrboPlaceholderLine } from '../TrboPlaceholderLine/TrboPlaceholderLine'

type Props = {
  columns: number
}

export function CCPlaceholderRows ({ columns }: Props) {
  return (
    <tbody>
      {Array(10).fill(null).map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array(columns).fill(null).map((_, colIndex) => (
            <td key={colIndex}>
              <TrboPlaceholderLine fullWidth size="large" />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
