// @flow

import React, { useEffect, useRef, type Node } from 'react'

import { outsideClick } from '../../../utils/dom'

export type Props = {
  isEnabled?: boolean,
  onOutsideClick: () => void,
  children?: Node
}

export function TrboOutsideClick ({ isEnabled, onOutsideClick, children, ...props }: Props) {
  const ref = useRef<any>()

  useEffect(() => {
    if (!isEnabled) return

    const removeListener = outsideClick(ref.current, onOutsideClick)

    return () => removeListener()
  }, [isEnabled, children])

  return (
    // $FlowFixMe
    <div ref={ref} {...props}>
      {children}
    </div>
  )
}
