// @flow

import React, { useState } from 'react'

import { CCButtonFilter, type CCButtonFilterProps } from '../../../components/UI'

import './CCButtonGroupFilter.scss'

export type Props = {
  filters: Array<CCButtonFilterProps>,
  defaultSelectedFilterIndex?: number,
  onSelect: (value: string) => void
}

export function CCButtonGroupFilter ({ defaultSelectedFilterIndex = 0, filters, onSelect }: Props) {
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(defaultSelectedFilterIndex)

  function handleClick (index, value) {
    onSelect(value)
    setSelectedFilterIndex(index)
  }

  return (
    <div className="trbo-button-group-filter">
      {filters.map((filter, index) => (
        <CCButtonFilter
          key={index}
          title={filter.title}
          icon={filter.icon}
          value={filter.value}
          isDisabled={filter.isDisabled}
          isSelected={index === selectedFilterIndex}
          onClick={() => handleClick(index, filter.value)}
        />
      ))}
    </div>
  )
}
