// @flow

import React from 'react'
import classNames from 'classnames'

import './CCIconButton.scss'

type Props = {
  iconName: string,
  iconVersion?: '6',
  iconWeight?: 'regular' | 'solid',
  size?: 'mini' | 'normal',
  role?: string,
  dataTestid?: string,
  onClick?: Function
}

export function CCIconButton ({
  iconName,
  iconVersion,
  iconWeight,
  size,
  role,
  dataTestid,
  onClick
}: Props) {
  const buttonClasses = classNames('cc-icon-button', {
    'cc-icon-button--mini': size === 'mini'
  })
  const iconClasses = classNames(`fa${iconVersion || ''}`, {
    [`fa${iconVersion || ''}-${iconName}`]: !!iconName,
    [`fa${iconVersion || ''}-regular`]: iconWeight === 'regular',
    [`fa${iconVersion || ''}-solid`]: iconWeight === 'solid'
  })

  return (
    <div
      className={buttonClasses}
      onClick={onClick}
      role={role}
      data-testid={dataTestid}
    >
      <i className={iconClasses} />
    </div>
  )
}
