// @flow

import React from 'react'
import moment from 'moment'

import { useShop } from '../../hooks'
import { ShopService } from '../../services'
import { Shop } from '../../models'

import { CCAlert } from '../UI'

type Props = {
  onClose: () => any
}

export function IntegrationAlert ({ onClose }: Props) {
  const [shop] = useShop()

  async function handleIgnoreOnClick (e: any) {
    e.preventDefault()
    onClose()
    const updatedShop = new Shop({
      ...shop,
      custom_config: {
        ...shop.custom_config,
        integrationAlertIgnoredAt: moment().utc().toISOString()
      }
    })
    await ShopService.updateChannel(shop.id, updatedShop)
  }

  return (
    <CCAlert
      type="error"
      header="There is some integration problem."
      content={
        <>
          <a
            href={`/shops/${shop.secure_id}/settings/integration`}
            rel="noopener noreferrer"
          >Click here to check your integration settings</a>
        </>
      }
      buttonActionLabel="Ignore warning"
      onActionClick={handleIgnoreOnClick}
    />
  )
}
