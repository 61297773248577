// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../models'

import { CCCustomizableTable, TrboPagination } from '../../../UI'

import { QA_COLUMNS } from './columns/QaColumns'
import { WEBSITE_COLUMNS } from './columns/WebsiteColumns'

import '../../../../../assets/shared/settings.scss'
import './KnowledgeItemsTableV2.scss'

type Props = {
  knowledgeItems: Array<KnowledgeItem>,
  isLoading: boolean,
  pages: number,
  page: number,
  onPageClick: (data: { selected: number }) => void,
  tab: string,
  onSortingChange: Function,
}

export function KnowledgeItemsTableV2 ({
  knowledgeItems,
  isLoading,
  pages,
  page,
  onPageClick,
  tab,
  onSortingChange
}: Props) {
  const initialPageIndex = page - 1

  const columns = {
    qa: QA_COLUMNS,
    website: WEBSITE_COLUMNS
  }

  return (
    <div className="knowledge-table-container">
      <CCCustomizableTable
        key={tab}
        showPlaceholderRows
        isLoading={isLoading}
        showSearch={false}
        columns={columns[tab]}
        rows={knowledgeItems}
        onSortingChange={onSortingChange}
        tableStyle="rounded"
      />
      <TrboPagination
        pages={pages}
        initialPage={initialPageIndex}
        onPageClick={onPageClick}
      />
    </div>
  )
}
