// @flow

import React from 'react'

import { useKnowledgeItems } from '../../../../../../../hooks'

import { QACSVForm } from './csv/QACSVForm'
import { QAManualForm } from './manual/QAManualForm'
import { QAFeedLinkForm } from './feedLink/QAFeedLinkForm'
import { QAOptions } from './QAOptions'

import './QAOptions.scss'

export function QADataSource () {
  const { dataSourceOptionModalType } = useKnowledgeItems()

  return (
    <div className="kb-qa-data-source">
      {!dataSourceOptionModalType && <QAOptions />}
      {dataSourceOptionModalType === 'csv' && <QACSVForm />}
      {dataSourceOptionModalType === 'manually' && <QAManualForm />}
      {dataSourceOptionModalType === 'link' && <QAFeedLinkForm />}
    </div>
  )
}
