// @flow

import React from 'react'

import { CCEmptyDataView } from '../../../UI'

import SearchNo from './SearchNo.png'

import './KnowledgeItemsEmptyListV2.scss'

export function KnowledgeItemsSearchEmptyList () {
  return (
    <div className="kb__empty-state">
      <img src={SearchNo} alt="empty-state" height="144px" width="144px" />
      <CCEmptyDataView
        title="No results found"
        description={<>We couldn&apos;t find what you are looking for.</>}
      />
    </div>
  )
}
