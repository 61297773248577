// @flow

import React from 'react'

import { App } from '../App'

import { Alerts } from './Alerts'

export default function AlertsContainer () {
  return (
    <App>
      <Alerts />
    </App>
  )
}
