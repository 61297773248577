// @flow

import React, { useState } from 'react'

import toastr from 'toastr'

import { KnowledgeItem } from '../../../../models'
import { useModal } from '../../../../hooks'

import { CCButton, CCCheckbox, CCIconButton, CCAlert, CCModal, TrboSubSegment, TrboOutsideClick } from '../../../UI'

import './KnowledgeBaseDeletion.scss'

type Props = {
  knowledgeItems: Array<KnowledgeItem>
}

export function KnowledgeBaseDeletion ({ knowledgeItems }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [csv, setCsv] = useState(false)
  const [manual, setManual] = useState(false)
  const [feed, setFeed] = useState(false)
  const [isDeleteModalOpen, , onDeleteRequestOpen, onDeleteRequestClose] = useModal()

  function onSave () {
    onDeleteRequestClose()
    setCsv(false)
    setManual(false)
    setFeed(false)
    toastr.info('Data source/s deleted')
  }

  return (
    <TrboOutsideClick
      isEnabled={dropdownOpen}
      onOutsideClick={() => setDropdownOpen(false)}
    >
      <div className="kb__selects_container" id="selectsContainer">
        <CCIconButton
          iconName="ellipsis"
          iconVersion="6"
          iconWeight="regular"
          onClick={(e) => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <div
            className="kb__selects_dropdown"
          >
            <CCButton
              icon="trash"
              iconVersion="6"
              iconWeight="regular"
              onClick={() => {
                setDropdownOpen(false)
                onDeleteRequestOpen()
              }}
            >
              Delete all
            </CCButton>
          </div>
        )}
        {isDeleteModalOpen &&
          <CCModal
            hasNewStyle
            isOpen
            className="delete-entries-modal"
            onRequestClose={onDeleteRequestClose}
            title="Delete all entries"
            description="Select what data source you want to delete"
            actions
            cancelButtonText="Cancel"
            saveButtonText="Delete"
            saveButtonColor="red"
            saveButtonDisabled={!csv && !manual && !feed}
            // todo: make delete button functional when source ticket is done - should actually delete all entries
            onSave={onSave}
            width={900}
          >
            <>
                <CCAlert
                  type="warning"
                  inline
                  header="Important!"
                  content="By deleting data sources, all of your entries will be permanently deleted and this cannot be undone"
                />
                <TrboSubSegment customClasses="kb-delete__checkbox--container">
                  <CCCheckbox
                    type="checkbox"
                    checked={csv}
                    className="checkbox"
                    label="All .csv files"
                    onChange={() => setCsv(!csv)}
                    // todo: make counter number dynamic when source ticket is done
                    counterLabel={100}
                  />
                </TrboSubSegment>
                <TrboSubSegment customClasses="kb-delete__checkbox--container">
                  <CCCheckbox
                    type="checkbox"
                    checked={manual}
                    className="checkbox"
                    label="All manual entries"
                    onChange={() => setManual(!manual)}
                    // todo: make counter number dynamic when source ticket is done
                    counterLabel={20}
                  />
                </TrboSubSegment>
                <TrboSubSegment customClasses="kb-delete__checkbox--container">
                  <CCCheckbox
                    type="checkbox"
                    checked={feed}
                    className="checkbox"
                    label="Imported feed (via link)"
                    onChange={() => setFeed(!feed)}
                  />
                </TrboSubSegment>
              </>
          </CCModal>
        }
      </div>
    </TrboOutsideClick>
  )
}
