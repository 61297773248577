// @flow

import React, { useEffect, useState } from 'react'
import { FeatureFlagService } from '../../../../services'
import { useKnowledgeBaseFeedLink, useKnowledgeItems, useShop } from '../../../../hooks'

import { JobsList } from '../../../Jobs/JobsList'
import { CCButtonGroupFilter, TrboPage, TrboPageHeader } from '../../../UI'

import { ImportButtonV2 } from './ImportButtonV2'
import { KnowledgeItemsEmptyListV2 } from './KnowledgeItemsEmptyListV2'
import { KnowledgeItemsTableV2 } from './KnowledgeItemsTableV2'
import { KnowledgeBaseListSearch } from './KnowledgeBaseListSearch'
import { DataSourceModal } from './dataSource/DataSourceModal'
import { KnowledgeItemsSearchEmptyList } from './KnowledgeItemsSearchEmptyList'

import './KnowledgeBaseListV2.scss'

// TODO; Thiago; 10.09.2024; Delete `KnowledgeBaseList` component and rename it to `KnowledgeBaseList` when it is ready to production.
export function KnowledgeBaseListV2 () {
  const [shop] = useShop()
  const {
    knowledgeItems,
    isLoadingList,
    pages,
    page,
    search,
    loadNextPage,
    loadKnowledgeItems,
    setSearch,
    orderValue,
    orderDirection,
    handleSortingClick
  } = useKnowledgeItems()
  const { load: loadKnowledgeBaseFeedLink } = useKnowledgeBaseFeedLink()
  const isShowingEmptyList = knowledgeItems.length === 0 && !isLoadingList

  const [tab, setTab] = useState('qa')

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function handleTabClick (tab): void {
    setTab(tab)
  }

  function onCompleteJob (): void {
    if (shop.secure_id) loadKnowledgeItems()
  }

  function renderContent (): any {
    if (isShowingEmptyList && search) return <KnowledgeItemsSearchEmptyList />
    if (isShowingEmptyList) return <KnowledgeItemsEmptyListV2 />

    return (
      <KnowledgeItemsTableV2
        knowledgeItems={knowledgeItems}
        isLoading={isLoadingList}
        pages={pages}
        page={page}
        onPageClick={handlePageClick}
        tab={tab}
        onSortingChange={handleSortingClick}
      />
    )
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadKnowledgeItems()
  }, [shop, orderValue, orderDirection])

  useEffect(() => {
    if (!shop.secure_id) return

    loadKnowledgeBaseFeedLink()
  }, [shop])

  useEffect(() => {
    if (!shop.secure_id) return
    const interval = setTimeout(loadKnowledgeItems, 500)

    return () => clearTimeout(interval)
  }, [search])

  const filterOptions = [
    { title: 'Q&A', icon: 'message-dots', value: 'qa' },
    FeatureFlagService.shouldShowOnStaging()
      ? { title: 'Website', icon: 'globe-pointer', value: 'website' }
      : { title: 'Website (Coming soon)', icon: 'globe-pointer', value: 'website', isDisabled: true },
    { title: 'File (Coming soon)', icon: 'file', value: 'file', isDisabled: true },
    { title: 'Text (Coming soon)', icon: 'pen-to-square', value: 'text', isDisabled: true }
  ]

  return (
    <TrboPage
      className="knowledge-base-list"
      header={
        <TrboPageHeader
          title="Knowledge Base"
          description='The knowledge base contains all relevant information for the customer service AI feature. The chatbot will only answer in the scope of the uploaded information.'
        >
          <ImportButtonV2 onComplete={loadKnowledgeItems} />
        </TrboPageHeader>
      }
    >
      <CCButtonGroupFilter
        filters={filterOptions}
        onSelect={handleTabClick}
      />
      <KnowledgeBaseListSearch
        onSearch={setSearch}
        knowledgeItems={knowledgeItems}
      />
      <div className="hide">
        <JobsList
          jobType="import-knowledge-items"
          title="Importation of knowledge items"
          onJobComplete={onCompleteJob}
        />
      </div>
      {renderContent()}
      <DataSourceModal />
    </TrboPage>
  )
}
