// @flow

import React from 'react'

import {
  CCDropdown,
  CCTextInput,
  CCTooltip,
  DropdownOption
} from '../../../../../UI'

type DeviceType = 'Desktop' | 'Mobile'

type DeviceAlign = 'left' | 'right'

type DeviceField = 'alignBot' | 'sideSpacing' | 'bottomSpacing'

type Props = {
  deviceType: DeviceType,
  alignBot?: DeviceAlign,
  sideSpacing?: number,
  bottomSpacing?: number,
  onChange: (field: DeviceField, value: DeviceAlign | number) => void
}

export function DevicePositioning ({
  deviceType,
  alignBot,
  sideSpacing,
  bottomSpacing,
  onChange
}: Props) {
  function handleChange (field: DeviceField) {
    return (value: any) => {
      onChange(field, value)
    }
  }

  return (
    <div className="device-positioning">
      <h2 className="settings-header">{deviceType} positioning</h2>
      <div className="display-flex gap-14">
        <div className="width100">
          <h3 className="settings-subheader">
            <span>Align bot </span>
            <CCTooltip title="<strong>Choose left or right for the chatbot position.</strong><br />It will appear in the bottom corner, and the chat dialog will open to the opposite side. Adjust spacing from the bottom and selected side."/>
          </h3>
          <CCDropdown
            onChange={(option: DropdownOption) => handleChange('alignBot')(option.value)}
            value={alignBot}
            options={[
              { label: 'Right', value: 'right' },
              { label: 'Left', value: 'left' }
            ]}
          />
        </div>
        <div className="width100">
          <h3 className="settings-subheader">
            Side spacing
          </h3>
          <CCTextInput
            customClasses="settings-input"
            onChange={handleChange('sideSpacing')}
            placeholder="Enter number"
            rightAddon="px"
            value={sideSpacing}
          />
        </div>
        <div className="width100">
          <h3 className="settings-subheader">
            Bottom spacing
          </h3>
          <CCTextInput
            customClasses="settings-input"
            onChange={handleChange('bottomSpacing')}
            placeholder="Enter number"
            rightAddon="px"
            value={bottomSpacing}
          />
        </div>
      </div>
    </div>
  )
}
