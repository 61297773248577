// @flow

export function outsideClick (element: Element, callback: () => void): () => void {
  function handleClick (event: any): void {
    if (!element) return
    const withinBoundaries = event.composedPath().includes(element)
    if (!withinBoundaries) {
      callback()
    }
  }

  window.addEventListener('click', handleClick, true)

  return () => window.removeEventListener('click', handleClick, true)
}
