// @flow

import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { useAlerts, useShop } from '../../hooks'
import { Alert } from '../../models'

import { IntegrationAlert } from './IntegrationAlert'
import { InvalidFeedLinkAlert } from './InvalidFeedLinkAlert'

import './Alerts.scss'

const ALERTS = {
  integrationAlert: IntegrationAlert,
  invalidFeedLinkAlert: InvalidFeedLinkAlert
}

export function Alerts () {
  const alertsRef = useRef()
  const [shop] = useShop()
  const { alerts, loadAlerts, hideAlert } = useAlerts()
  const [alertsHeight, setAlertsHeight] = useState(0)
  const [animationState, setAnimationState] = useState<'idle' | 'in_progress' | 'done'>('idle')
  const classes = classNames('alerts', {
    'alerts--is-animating': animationState === 'in_progress',
    'alerts--is-visible': alerts.length > 0
  })
  const styles = {
    '--alerts-height': alertsHeight === 0 || animationState === 'done'
      ? 'auto'
      : `${alertsHeight}px`
  }

  function handleHide (alert: Alert) {
    hideAlert(alert)
  }

  function renderAlerts () {
    return alerts
      .filter((alert) => alert.isVisible)
      .map((alert) => {
        const Component = ALERTS[alert.name]

        if (!Component) return null

        return (
          <Component
            key={alert.name}
            onClose={() => handleHide(alert)}
          />
        )
      })
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadAlerts()
  }, [shop])

  useEffect(() => {
    if (alerts.length > 0 && animationState === 'idle') {
      setAlertsHeight(alertsRef.current?.offsetHeight ?? 0)
      setAnimationState('in_progress')
    }
  }, [alerts, animationState])

  useEffect(() => {
    if (animationState !== 'in_progress') return

    const interval = setTimeout(() => setAnimationState('done'), 1000)

    return () => clearTimeout(interval)
  }, [animationState])

  if (alerts.length === 0) return null

  return (
    <div ref={alertsRef} className={classes} style={styles}>
      {renderAlerts()}
    </div>
  )
}
