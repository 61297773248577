// @flow

import React from 'react'
import classNames from 'classnames'

import { TrboIcon } from '../../../../UI'

import './DataSourceDropdownItem.scss'

type Props = {
  icon: string,
  color: string,
  title: string,
  description: string,
  isDisabled?: boolean,
  onClick?: () => void
}

export function DataSourceDropdownItem ({ icon, color, title, description, isDisabled, onClick }: Props) {
  const classes = classNames('kb-data-source-dropdown-item', {
    'kb-data-source-dropdown-item--disabled': isDisabled
  })

  return (
    <div className={classes} onClick={onClick}>
      <div className="kb-data-source-dropdown-item__wrapper">
        <div className="kb-data-source-dropdown-item__icon">
          <TrboIcon
            padded
            name={icon}
            color={color}
          />
        </div>
        <div className="kb-data-source-dropdown-item__content">
          <div className="kb-data-source-dropdown-item__title">
            {title}
          </div>
          <div className="kb-data-source-dropdown-item__description">
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}
