// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../../models'

import { CCModal } from '../../../../../UI'

import '../ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  },
  onViewRequestClose: Function
}

export function ViewModal ({ row, onViewRequestClose }: Props) {
  return (
    <CCModal
      isOpen
      width={900}
      title="View Q&A"
      confirmLabel="Delete"
      onRequestClose={onViewRequestClose}
      customClasses="k-base__view-q-a__modal"
      hasNewStyle
    >
      <div className="k-base__view-q-a">
        <div className="k-base__view-q-a__content">
          <div className="k-base__view-q-a__heading">Question</div>
          <div className="k-base__view-q-a__text question">
            {row.original.question}
          </div>
        </div>
        <div className="k-base__view-q-a__content">
          <div className="k-base__view-q-a__heading">Answer</div>
          <div className="k-base__view-q-a__text answer">
            {row.original.answer}
          </div>
        </div>
      </div>
    </CCModal>
  )
}
