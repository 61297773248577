// @flow

export function validateURL (url: string): boolean {
  const urlPattern = new RegExp(
    '^https?:\\/\\/' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})' +
    '(\\:\\d+)?' +
    '(\\/[-a-z\\d%_.~+\\/]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d%_.~+=-]*)?$',
    'i'
  )
  return !!urlPattern.test(url)
}
