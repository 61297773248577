// no flow yet

import React, { Component } from 'react'
import classnames from 'classnames'

import './CCTextInput.scss'

const nop = () => {
}

type Props = {
  autoFocus?: boolean,
  customClasses: string,
  disabled?: boolean,
  error?: boolean,
  errorMessage?: any,
  innerRef: any,
  onChange: Function,
  onClick: Function,
  onKeyPress: Function,
  onPressEnterKey?: Function,
  onBlur?: Function,
  placeholder?: string,
  maxLength?: number,
  value: string,
  resetOnEnter?: boolean,
  inputClasses?: string,
  type?: string,
  maxNumber?: number,
  minNumber?: number,
  role?: string,
  id?: string,
  onPaste?: Function,
  rightAddon?: string
}

type State = {
  value: string
}

export class CCTextInput extends Component<Props, State> {
  static defaultProps = {
    customClasses: '',
    onChange: nop,
    onClick: nop,
    onKeyPress: nop,
    innerRef: React.createRef(),
    type: 'text'
  }

  // TODO: mihail; 22.08.19; This actually sets the props only the first time
  // and doesn't update them afterwards
  state = {
    value: this.props.value
  }

  componentDidMount () {
    if (this.props.autoFocus) { this.props.innerRef.current.focus() }
  }

  handleChange = (e: any) => {
    this.setState({
      value: e.target.value
    })

    this.props.onChange(e.target.value)
  }

  handleBlur = (e: any) => {
    const { onBlur } = this.props
    const { value } = this.state

    if (onBlur) onBlur(value)
  }

  handleKeyPress = (e: KeyboardEvent) => {
    const { value } = this.state
    const { resetOnEnter, onKeyPress, onPressEnterKey } = this.props
    onKeyPress(e, value)

    if (e.key === 'Enter') {
      if (resetOnEnter) this.setState({ value: '' })
      if (onPressEnterKey) onPressEnterKey(value)
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.value === this.props.value) return
    this.setState({ value: this.props.value })
  }

  setValue (value: string) {
    this.setState({ value })
  }

  render () {
    const {
      onClick,
      placeholder,
      innerRef,
      disabled,
      maxLength,
      inputClasses,
      error,
      errorMessage,
      type,
      minNumber,
      maxNumber,
      id,
      role = 'text-input',
      onPaste,
      rightAddon
    } = this.props

    const { value } = this.state

    const classes = classnames(
      'cc-text-input',
      this.props.customClasses,
      {
        'cc-text-input--error': this.props.error,
        'cc-text-input--with-right-addon': !!rightAddon
      })

    return (
      <div className={classes}>
        <input
          id={id}
          role={role}
          type={type}
          value={(value === undefined || value === null) ? '' : value}
          onInput={this.handleChange}
          onChange={nop}
          onBlur={this.handleBlur}
          onClick={onClick}
          placeholder={placeholder}
          ref={innerRef}
          onKeyDown={this.handleKeyPress}
          disabled={disabled}
          maxLength={maxLength}
          className={inputClasses}
          max={maxNumber}
          min={minNumber}
          onPaste={onPaste}
        />
        {rightAddon && (
          <div className="cc-text-input__right-addon">
            {rightAddon}
          </div>
        )}
        {error && errorMessage && <div className="cc-text-input__error-msg">
          {errorMessage}
        </div>}
      </div>
    )
  }
}
