// @flow

import React from 'react'
import classNames from 'classnames'

import './TrboPlaceholderLine.scss'

export type Props = {
  size?: 'small' | 'large',
  delay?: number,
  darker?: boolean,
  fullWidth?: boolean
}

export function TrboPlaceholderLine ({ size = 'small', delay, darker, fullWidth }: Props) {
  const classes = classNames('trbo-placeholder-line', {
    [`trbo-placeholder-line--${size}`]: true,
    'trbo-placeholder-line--darker': darker,
    'trbo-placeholder-line--full-width': fullWidth
  })
  const styles: any = {}
  if (delay) styles['--trbo-placeholder-line-delay'] = `${delay}ms`

  return <div className={classes} style={styles}></div>
}
