// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'

import { CCLabel, type CCLabelColor } from '../../../../UI'

type Props = {
  knowledgeItem: KnowledgeItem
}

function getText (knowledgeItem: KnowledgeItem): string {
  const values = {
    training: 'Training',
    trained: 'Trained',
    error: 'Error'
  }

  return values[knowledgeItem.status]
}

function getColor (knowledgeItem: KnowledgeItem): CCLabelColor {
  const values = {
    training: 'gray',
    trained: 'success-green',
    error: 'red'
  }

  return values[knowledgeItem.status]
}

export function StatusCell ({ knowledgeItem }: Props) {
  return <CCLabel text={getText(knowledgeItem)} color={getColor(knowledgeItem)} />
}
