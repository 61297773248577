// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'

import { CCButton } from '../../../../UI'

type Props = {
  knowledgeItem: KnowledgeItem
}

function getText (knowledgeItem: KnowledgeItem): string {
  const values = {
    csv: '.csv file',
    feed_link: 'Feed link',
    website: 'Website',
    manual: 'Manual'
  }

  return values[knowledgeItem.input_source]
}

function getIcon (knowledgeItem: KnowledgeItem): string {
  const values = {
    csv: 'file-csv',
    feed_link: 'link',
    website: 'globe-pointer',
    manual: 'pen-line'
  }

  return values[knowledgeItem.input_source]
}

export function InputSourceCell ({ knowledgeItem }: Props) {
  return (
    <CCButton
      size="xsmall"
      customClasses="input-source-cell-button"
      onClick={() => null}
    >
      <i className={`fa6 fa6-regular fa6-${getIcon(knowledgeItem)}`} />
      {getText(knowledgeItem)}
    </CCButton>
  )
}
