// @flow

import React from 'react'

import { type KnowledgeDataSourceOptionType } from '../../../../../../../models'
import { useKnowledgeBaseFeedLink, useKnowledgeItems } from '../../../../../../../hooks'

import { FeedLinkButtons } from './FeedLinkButtons'
import { QAOptionItem } from './QAOptionItem'

import './QAOptions.scss'

export function QAOptions () {
  const { setDataSourceOptionModalType } = useKnowledgeItems()
  const { settings: knowledgeBaseFeedLinkSettings } = useKnowledgeBaseFeedLink()

  function handleClick (qaOptionType: KnowledgeDataSourceOptionType) {
    return () => {
      setDataSourceOptionModalType(qaOptionType)
    }
  }

  return (
    <div className="kb-qa-options">
      <QAOptionItem
        icon="link-horizontal"
        title="Via feed link"
        titleCheckIcon={!!knowledgeBaseFeedLinkSettings}
        description="Automatically reads the data through your Google Sheets link"
        customActions={<FeedLinkButtons />}
      />
      <QAOptionItem
        icon="file-csv"
        title="Via Import .csv file "
        description="Import .csv file manually to generate Q&As"
        onClick={handleClick('csv')}
      />
      <QAOptionItem
        icon="pen-line"
        title="Write manually"
        description="Enter questions and answers manually"
        onClick={handleClick('manually')}
      />
    </div>
  )
}
