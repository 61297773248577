// @flow

import React, { useState } from 'react'

import { KnowledgeItem } from '../../../../models'

import './KnowledgeBaseListSearch.scss'
import { KnowledgeBaseDeletion } from './KnowledgeBaseDeletion'

type Props = {
  onSearch: (query: string) => void,
  knowledgeItems: Array<KnowledgeItem>
}

export function KnowledgeBaseListSearch ({ onSearch, knowledgeItems }: Props) {
  const [searchQuery, setSearchQuery] = useState('')

  function handleSearchChange (event) {
    const query = event.target.value?.toLowerCase()
    setSearchQuery(query)
    onSearch(query)
  }

  return (
    <div className="kb__control_container">
      <div className="kb__search_container" id="searchContainer">
        <i className="fa6 fa6-search"></i>
        <input
          type='text'
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by question & answer"
          className="kb__search_input"
        />
      </div>
      <KnowledgeBaseDeletion knowledgeItems={knowledgeItems} />
    </div>
  )
}
