// @flow

import React, { type Node } from 'react'
import classnames from 'classnames'

import './CCSubSegment.scss'

type Props = {
  children: Node,
  customClasses: string
}

export function CCSubSegment ({ children, customClasses }: Props) {
  const classes = classnames('cc-sub-segment', customClasses)

  return (
    <div className={classes}>
      {children}
    </div>
  )
}
