// @flow

import { Alert, Shop } from '../models'
import { CCRoutes } from '../utils/routes'

import { axiosJson } from './utils'

export class AlertsService {
  static loadAlerts (shop: Shop): Promise<any> {
    return axiosJson.get(CCRoutes.alertsPath(shop))
      .then(({ data }) => data.alerts.map((alert) => new Alert(alert)))
      .catch(() => ([]))
  }
}
