// @flow

import React from 'react'

import { TrboIcon } from '../TrboIcon/TrboIcon'

import csvIcon from './iconExtensions/csv.svg'

import './IconExtension.scss'

type Props = {
  extensions?: Array<string>
}

const CUSTOM_ICONS = {
  csv: csvIcon
}

export function IconExtension ({ extensions }: Props) {
  function defaultIcon () {
    return <TrboIcon name="file" />
  }

  if (!extensions) return defaultIcon()
  if (extensions.length > 1) return defaultIcon()

  const firstExtension = extensions[0]
  if (CUSTOM_ICONS[firstExtension]) return <img src={CUSTOM_ICONS[firstExtension]} className="custom-icon-extension" />

  return <TrboIcon name={firstExtension} />
}
