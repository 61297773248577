// @flow

import React, { type Node } from 'react'

import { useKnowledgeItems, useWebpageForm } from '../../../../../hooks'

import { CCButton, CCSpinnerSmall } from '../../../../UI'

import './DataSourceSettingsTemplate.scss'

type Props = {
  children: Node,
  buttonSaveLabel?: string,
  savingLabel?: string,
  isSaveButtonDisabled?: boolean,
  isSaving?: boolean,
  onBackClick: () => void,
  onSaveClick: () => any,
  hideBackButton?: boolean
}

export function DataSourceSettingsTemplate ({
  children,
  buttonSaveLabel = 'Save',
  savingLabel = 'Saving...',
  isSaveButtonDisabled,
  isSaving,
  onBackClick,
  onSaveClick,
  hideBackButton = false
}: Props) {
  const { closeModal } = useKnowledgeItems()
  const { clearWebpages } = useWebpageForm()

  function onRequestClose () {
    clearWebpages()
    closeModal()
  }

  return (
    <div className="kb-data-source-settings-template">
      {!hideBackButton &&
        <div className="kb-data-source-settings-template__header">
          <CCButton
            size="small"
            icon="arrow-left"
            iconVersion="6"
            onClick={onBackClick}
          >
            Back
          </CCButton>
        </div>
      }
      <div className="kb-data-source-settings-template__content">
        {children}
      </div>
      <div className="kb-data-source-settings-template__actions">
        {isSaving && (
          <div className="kb-data-source-settings-template__actions__saving">
            <CCSpinnerSmall />
            <span>{savingLabel}</span>
          </div>
        )}
        {!isSaving && (
          <>
            <CCButton
              onClick={onRequestClose}
            >
              Cancel
            </CCButton>
            <CCButton
              color="primary"
              disabled={isSaveButtonDisabled}
              onClick={onSaveClick}
            >
              {buttonSaveLabel}
            </CCButton>
          </>
        )}
      </div>
    </div>
  )
}
