// no flow yet

import React from 'react'
import { sanitizeNonBreaking, sanitizeWhiteSpace } from '../../../utils/sanitize'
import { FolderFilter } from '../../Chatbots/pages/ChatbotList/FolderFilter'
import { CCColumnsSelector } from './components/CCColumnsSelector'

import { type CCTableColumn } from './types/CCTableColumn'
import { CCTableSearchFilter, SEARCH_FILTER_KEY, searchFilter } from './components/CCTableSearchFilter'
import { CCTableWithSummary } from './CCTableWithSummary'

type Props = {
  columns: Array<CCTableColumn>,
  rows: Array<any>,
  isLoading?: boolean,
  showPlaceholderRows?: boolean,
  onRowClick: Function,
  columnsPresetActive?: string,
  columnsPresetOptions?: Array<Object>,
  customRowClasses?: string,
  onColumnChange?: Function,
  onPresetChange?: Function,
  onRowDelete?: Function,
  rowDeletable?: boolean,
  showSearch?: boolean,
  showFolderSelect?: boolean,
  onFolderChange?: Function,
  selectedFolder?: string,
  dropdownMenu?: Function,
  summaryRow?: Object,
  onSortingChange?: Function,
  tableStyle?: 'default' | 'skinny' | 'rounded',
}

type State = {
  rows: Array<Object>,
  filteredRows: Array<Object>,
  selectedColumns: Array<CCTableColumn>,
  filters: Object
}

export class CCCustomizableTable extends React.Component<Props, State> {
  state = {
    rows: this.props.rows,
    filteredRows: [],
    selectedColumns: [...this.props.columns],
    filters: {}
  }

  static defaultProps = {
    rowDeletable: true,
    showSearch: true,
    showFolderSelect: false
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps = (newProps: Props) => {
    if (newProps.rows !== this.state.rows) {
      const searchValue = (document.getElementById('cc-search-input') && document.getElementById('cc-search-input').value) || ''
      this.setState({ rows: newProps.rows }, () => {
        this.updateFilter(SEARCH_FILTER_KEY, searchFilter(searchValue))
      })
    }
  }

  /* eslint-enable camelcase */

  handleColumnChange = (selectedColumns: Array<CCTableColumn>) => {
    this.setState({ selectedColumns }, () => {
      if (this.props.onColumnChange) this.props.onColumnChange(this.state.selectedColumns.map(c => c.Header))
    })
  }

  updateFilter = (name: string, filter: Function) => {
    const { filters } = this.state
    filters[name] = filter
    this.setState({ filters }, () => { this.applyFilters() })
  }

  updateFolderFilter = (name: string, filter: Function, folderName: string) => {
    this.updateFilter(name, filter)
    if (this.props.onFolderChange) this.props.onFolderChange(folderName)
  }

  componentDidMount (): void {
    this.applyFilters()
  }

  componentDidUpdate (): void {
    this.applyFilters()
  }

  applyFilters = () => {
    let filteredRows = this.state.rows.map(row => {
      return {
        ...row,
        name: sanitizeWhiteSpace(sanitizeNonBreaking(row.name))
      }
    })

    for (const filter of Object.values(this.state.filters)) {
      filteredRows = filteredRows.filter(filter)
    }

    if (JSON.stringify(this.state.filteredRows) !== JSON.stringify(filteredRows)) this.setState({ filteredRows })
  }

  renderControls () {
    return (
      <React.Fragment>
        {(this.props.showFolderSelect) && (<FolderFilter selectedFolder={this.props.selectedFolder} updateFilter={this.updateFolderFilter}/>)}
        {(this.props.showSearch) && (<CCTableSearchFilter updateFilter={this.updateFilter}/>)}
        {(this.props.columnsPresetActive && this.props.columnsPresetOptions) && (<CCColumnsSelector
          existingColumns={this.props.columns}
          columnsPresetActive={this.props.columnsPresetActive}
          columnsPresetOptions={this.props.columnsPresetOptions}
          onPresetChange={this.props.onPresetChange}
          onColumnChange={this.handleColumnChange}
        />)}
      </React.Fragment>
    )
  }

  render () {
    const {
      isLoading,
      showPlaceholderRows,
      summaryRow,
      dropdownMenu,
      onRowClick,
      onRowDelete,
      customRowClasses,
      rowDeletable,
      tableStyle,
      onSortingChange
    } = this.props
    const { filteredRows, selectedColumns } = this.state

    return (<CCTableWithSummary
      isLoading={isLoading}
      showPlaceholderRows={showPlaceholderRows}
      summaryRow={summaryRow}
      dropdownMenu={dropdownMenu}
      controls={this.renderControls()}
      data={filteredRows}
      onRowDelete={onRowDelete}
      onRowClick={onRowClick}
      columns={selectedColumns}
      customRowClasses={customRowClasses}
      tableStyle={tableStyle}
      onSortingChange={onSortingChange}
      rowDeletable={rowDeletable}/>)
  }
}
