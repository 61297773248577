// @flow

import React from 'react'
import classNames from 'classnames'

import { CCButton } from '../CCButton/CCButton'

import './CCButtonFilter.scss'

export type CCButtonFilterProps = {
  title: string,
  icon: string,
  value: string,
  isSelected?: boolean,
  isDisabled?: boolean
}

export type Props = CCButtonFilterProps & {
  onClick?: () => void
}

export function CCButtonFilter ({ title, icon, isSelected, isDisabled, onClick }: Props) {
  const classes = classNames('trbo-button-filter', {
    'trbo-button-filter--is-selected': isSelected
  })

  function renderIcon () {
    if (!icon) return

    const iconClasses = classNames('fa6', {
      [`fa6-regular fa6-${icon}`]: true,
      [`cc-button-filter__icon-${icon}`]: true
    })

    return (<i className={iconClasses}/>)
  }

  return (
    <CCButton
      customClasses={classes}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className='trbo-button-filter__content'>
        {renderIcon()}
        <div className="trbo-button-filter__content__text">{title}</div>
      </div>
    </CCButton>
  )
}
